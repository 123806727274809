import Cookies from 'js-cookie'
import { createRouter, createWebHistory } from 'vue-router'

import AudienceNeue from '../components/neue/AudienceNeue'
import store from '../store'
import OrderDetailNeue from '../view/activity/OrderDetailNeue'
import OrdersNeue from '../view/activity/OrdersNeue'
import Auth from '../view/Auth'
import ListsNeue from '../view/customers/ListsNeue'
import MessagesNeue from '../view/customers/MessagesNeue'
import SellNeue from '../view/store/SellNeue'
import MessagesTab from '../view/customers/MessagesTab'
import ProfileTab from '../view/customers/ProfileTab'
import ForgotPassword from '../view/ForgotPassword'
import GuidesTab from '../view/GuidesTab'
import Login from '../view/Login'
import ReportsNeue from '../view/ReportsNeue'
import PackingSlipSettings from '../view/PackingSlipSettings'
import SettingsTab from '../view/SettingsTab'
import SignupReferral from '../view/SignupReferral'
import SignupAreaCode from '../view/SignupAreaCode'
import SignupBusinessName from '../view/SignupBusinessName'
import SignupBusinessType from '../view/SignupBusinessType'
import SignupEmail from '../view/SignupEmail'
import SignupPassword from '../view/SignupPassword'
import StandardLayout from '../view/StandardLayout'
import AccountCreditList from '../view/store/AccountCreditList'
import DiscountCodeDetail from '../view/store/DiscountCodeDetail'
import DiscountCodeList from '../view/store/DiscountCodeList'
import DiscountCodeNew from '../view/store/DiscountCodeNew'
import DropDetail from '../view/store/DropDetail'
import DropList from '../view/store/DropList'
import DropNew from '../view/store/DropNew'
import FulfillmentMethodList from '../view/store/FulfillmentMethodList'
import FulfillmentMethodNew from '../view/store/FulfillmentMethodNew'
import FulfillmentMethodNeue from '../components/neue/FulfillmentMethodNeue'
import AutomationNeue from '../components/neue/AutomationNeue'
import AutomationList from '../components/neue/AutomationList'
import GiftCardDetail from '../view/store/GiftCardDetail'
import GiftCardList from '../view/store/GiftCardList'
import GiftCardNew from '../view/store/GiftCardNew'
import LabelDetail from '../view/store/LabelDetail'
import LabelList from '../view/store/LabelList'
import LabelNew from '../view/store/LabelNew'
import LoyaltyProgramDetail from '../view/store/LoyaltyProgramDetail'
import LoyaltyProgramList from '../view/store/LoyaltyProgramList'
import MembershipTierDetail from '../view/store/MembershipTierDetail'
import MembershipTierNeue from '../view/store/MembershipTierNeue'
import MembershipTierList from '../view/store/MembershipTierList'
import MembershipTierNew from '../view/store/MembershipTierNew'
import StorePrefs from '../components/StorePrefs'
import DomainsNeue from '../view/DomainsNeue'
import ModulesNeue from '../view/ModulesNeue'
import ProductDetail from '../view/store/ProductDetail'
import ProductNeue from '../view/store/ProductNeue'
import ProductList from '../view/store/ProductList'
import ProductFolders from '../view/store/ProductFolders'
import ProductNew from '../view/store/ProductNew'
import ReferralNeue from '../view/store/ReferralNeue'
import StoreList from '../view/store/StoreList'
import StoreNeue from '../view/store/StoreNeue'
import StoreNew from '../view/store/StoreNew'
import StripeIntegrationRequired from '../view/StripeIntegrationRequired'
import RestartStripeIntegration from '../view/RestartStripeIntegration'
import PayPastDueStripeBalance from '../view/PayPastDueStripeBalance'
import LinksNeue from '@/view/LinksNeue.vue'

const routes = [
  {
    path: '/',
    component: StandardLayout,
    redirect: '/orders',
    children: [
      {
        path: '/guides',
        meta: {
          title: 'Guides'
        },
        component: GuidesTab
      },
      {
        path: '/settings',
        meta: {
          title: 'Settings'
        },
        component: SettingsTab
      },
      {
        path: '/messages/:id',
        meta: {
          title: 'Messages'
        },
        component: MessagesTab
      },
      {
        path: '/profile/:id',
        meta: {
          title: 'Profile'
        },
        component: ProfileTab
      },
      {
        path: '/lists/:listid/profile/:id',
        meta: {
          title: 'Profile'
        },
        component: ProfileTab
      },
      {
        path: '/messages',
        meta: {
          title: 'Messages'
        },
        component: MessagesNeue
      },
      {
        path: '/lists',
        meta: {
          title: 'Lists'
        },
        component: ListsNeue
      },
      {
        path: '/lists/:id',
        meta: {
          title: 'List'
        },
        component: AudienceNeue
      },
      {
        path: '/orders',
        meta: {
          title: 'Orders'
        },
        component: OrdersNeue
      },
      {
        path: '/order/:id',
        meta: {
          title: 'Order'
        },
        component: OrderDetailNeue
      },
      {
        path: '/reports',
        component: ReportsNeue
      },
      {
        path: '/reports/packing-slips',
        component: PackingSlipSettings
      },
      {
        path: '/sell',
        meta: {
          title: 'Sell'
        },
        component: SellNeue
      },
      {
        path: '/store',
        redirect: '/store/stores'
      },
      {
        path: '/store/products',
        meta: {
          title: 'Product library'
        },
        component: ProductList
      },
      {
        path: '/store/folders',
        meta: {
          title: 'Folders'
        },
        component: ProductFolders
      },
      {
        path: '/store/products/:id',
        meta: {
          title: 'Product'
        },
        component: ProductNeue
      },
      {
        path: '/store/products/:id/old',
        meta: {
          title: 'Product'
        },
        component: ProductDetail
      },
      {
        path: '/store/products/new',
        meta: {
          title: 'New product'
        },
        component: ProductNew
      },
      {
        path: '/store/stores',
        meta: {
          title: 'Stores'
        },
        component: StoreList
      },
      {
        path: '/store/stores/:id',
        meta: {
          title: 'Store'
        },
        component: StoreNeue
      },
      {
        path: '/store/stores/new',
        meta: {
          title: 'New store'
        },
        component: StoreNew
      },
      {
        path: '/store/labels',
        meta: {
          title: 'Labels'
        },
        component: LabelList
      },
      {
        path: '/store/labels/:id',
        meta: {
          title: 'Label'
        },
        component: LabelDetail
      },
      {
        path: '/store/labels/new',
        meta: {
          title: 'New label'
        },
        component: LabelNew
      },
      {
        path: '/store/discount-codes',
        meta: {
          title: 'Discount codes'
        },
        component: DiscountCodeList
      },
      {
        path: '/store/discount-codes/:id',
        meta: {
          title: 'Discount code'
        },
        component: DiscountCodeDetail
      },
      {
        path: '/store/discount-codes/new',
        meta: {
          title: 'New discount code'
        },
        component: DiscountCodeNew
      },
      {
        path: '/store/gift-cards',
        meta: {
          title: 'Gift cards'
        },
        component: GiftCardList
      },
      {
        path: '/store/gift-cards/:id',
        meta: {
          title: 'Gift card'
        },
        component: GiftCardDetail
      },
      {
        path: '/store/gift-cards/new',
        meta: {
          title: 'New gift gard'
        },
        component: GiftCardNew
      },
      {
        path: '/store/account-credits',
        meta: {
          title: 'Account credits'
        },
        component: AccountCreditList
      },
      {
        path: '/store/fulfillment-methods',
        meta: {
          title: 'Fulfillment methods'
        },
        component: FulfillmentMethodList
      },
      {
        path: '/store/fulfillment-methods/:id',
        meta: {
          title: 'Fulfillment method'
        },
        component: FulfillmentMethodNeue
      },
      {
        path: '/store/fulfillment-methods/new',
        meta: {
          title: 'New fulfillment method'
        },
        component: FulfillmentMethodNew
      },
      {
        path: '/store/membership-tiers',
        meta: {
          title: 'Membership tiers'
        },
        component: MembershipTierList
      },
      {
        path: '/store/membership-tiers/:id/old',
        meta: {
          title: 'Membership tier'
        },
        component: MembershipTierDetail
      },
      {
        path: '/store/membership-tiers/:id',
        meta: {
          title: 'Membership tier'
        },
        component: MembershipTierNeue
      },
      {
        path: '/store/membership-tiers/new',
        meta: {
          title: 'New membership tier'
        },
        component: MembershipTierNew
      },
      {
        path: '/store/drops',
        meta: {
          title: 'Drops'
        },
        component: DropList
      },
      {
        path: '/store/drops/:id',
        meta: {
          title: 'Drop'
        },
        component: DropDetail
      },
      {
        path: '/store/drops/new',
        meta: {
          title: 'New drop'
        },
        component: DropNew
      },
      {
        path: '/store/loyalty-programs',
        meta: {
          title: 'Loyalty programs'
        },
        component: LoyaltyProgramList
      },
      {
        path: '/store/loyalty-programs/:id',
        meta: {
          title: 'Loyalty program'
        },
        component: LoyaltyProgramDetail
      },
      {
        path: '/store/referral-program',
        meta: {
          title: 'Referral program'
        },
        component: ReferralNeue
      },
      {
        path: '/storefront',
        meta: {
          title: 'Storefront'
        },
        component: StorePrefs
      },
      {
        path: '/storefront/domains',
        meta: {
          title: 'Domains'
        },
        component: DomainsNeue
      },
      {
        path: '/storefront/modules',
        meta: {
          title: 'Content blocks'
        },
        component: ModulesNeue
      },
      {
        path: '/storefront/links',
        name: 'Links',
        component: LinksNeue,
        meta: { requiresAuth: true }
      },

      {
        path: '/automation',
        meta: {
          title: 'Automation'
        },
        component: AutomationList
      },
      {
        path: '/automation/message/:id',
        meta: {
          title: 'Automation',
          automation: 'message'
        },
        component: AutomationNeue
      },
      {
        path: '/automation/drop/:id',
        meta: {
          title: 'Automation',
          automation: 'drop'
        },
        component: AutomationNeue
      },
      {
        path: '/store/passes',
        name: 'passes',
        component: () => import('../view/store/Passes.vue')
      },
      {
        path: '/store/passes/:id',
        name: 'pass-edit',
        component: () => import('../view/store/PassEdit.vue')
      }
    ]
  },
  {
    path: '/sessions/new',
    component: Auth,
    meta: { noAuth: true }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Login',
      noAuth: true
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'Forgot password',
      noAuth: true
    }
  },
  {
    path: '/sign-up',
    component: SignupEmail,
    meta: {
      title: 'Sign up',
      noAuth: true
    }
  },
  {
    path: '/password',
    component: SignupPassword,
    meta: {
      title: 'Password',
      noAuth: true
    }
  },
  {
    path: '/business-name',
    component: SignupBusinessName,
    meta: {
      title: 'BusinessName',
      noAuth: true
    }
  },
  {
    path: '/business-type',
    component: SignupBusinessType,
    meta: {
      // title: 'BusinessType',
      noAuth: true
    }
  },
  {
    path: '/area-code',
    component: SignupAreaCode,
    meta: {
      // title: 'AreaCode',
      noAuth: true
    }
  },
  {
    path: '/referral',
    component: SignupReferral,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/stripe-integration-required',
    component: StripeIntegrationRequired,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/restart-stripe-integration',
    component: RestartStripeIntegration,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/pay-past-due-stripe-balance',
    component: PayPastDueStripeBalance,
    meta: {
      noAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const appName = 'Bottle Dashboard'
router.beforeEach((to, from, next) => {
  let _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', to.fullPath])
  _hsq.push(['trackPageView'])
  // Meta
  document.title = to.meta.title ? `${to.meta.title} | ${appName}` : appName
  // Auth Requirements
  if (!(to.matched.some(record => record.meta.noAuth) || to.path === '/') && !Cookies.get('auth_token')) {
    next({
      path: '/login',
      params: { redirect: to.fullPath }
    })
  } else next()
})
router.afterEach((to, from) => {
  fbq('track', 'PageView')
  // TODO(alicja): put the HubSpot tracker here too?
})

export default router
