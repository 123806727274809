
import { defineComponent, ref, computed, onMounted } from 'vue'
import InfoCard from '@/components/InfoCard.vue'
import BasicSpinner from '@/components/BasicSpinner.vue'
import TheModal from '@/components/TheModal.vue'
import MultiSelectInput from '@/components/neue/MultiSelectInput.vue'
import PassBenefits from '@/components/customers/PassBenefits.vue'
import api from '@/store/api'
import { mapType } from '@/store/mappers'

export default defineComponent({
  name: 'PassInfoCard',
  components: {
    InfoCard,
    BasicSpinner,
    TheModal,
    MultiSelectInput,
    PassBenefits
  },
  emits: ['cancel-pass', 'refund-pass'],
  props: {
    membershipTier: {
      type: Object,
      required: true
    },
    membershipPass: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    customerName: {
      type: String,
      required: true
    },
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const showCancelModal = ref(false)
    const showRefundModal = computed({
      get: () => refundModalOpen.value,
      set: val => {
        refundModalOpen.value = val
        if (!val) {
          selectedPayments.value = []
        }
      }
    })

    const selectedPayments = ref([])

    const paymentTransactions = computed(() => mapType('membership_pass_payment_transaction'))

    const getPaymentTransactions = () => {
      api.call('GET', `conversation_user_pairs/${props.conversationUserPairId}/membership_passes/payment_transactions`)
    }

    const paymentOptions = computed(() => {
      return paymentTransactions.value.map(transaction => ({
        name: `${transaction.attributes.transaction_date} - ${transaction.attributes.amount.format}${
          transaction.attributes.is_refunded ? ' (Refunded)' : ''
        }`,
        value: transaction.id,
        disabled: transaction.attributes.is_refunded
      }))
    })

    const handleCancelConfirm = () => {
      emit('cancel-pass', props.membershipPass.id)
      showCancelModal.value = false
    }

    const handleRefundConfirm = () => {
      selectedPayments.value.forEach(paymentId => {
        api.call(
          'POST',
          `conversation_user_pairs/${props.conversationUserPairId}/membership_passes/payment_transactions/${paymentId}/refund`,
          {
            membership_pass_selection: {
              membership_pass_id: props.membershipPass.id
            }
          }
        )
      })
      showRefundModal.value = false
    }

    const refundModalOpen = ref(false)

    const openRefundModal = () => {
      getPaymentTransactions()
      refundModalOpen.value = true
    }

    return {
      showCancelModal,
      showRefundModal,
      handleCancelConfirm,
      handleRefundConfirm,
      selectedPayments,
      paymentOptions,
      openRefundModal
    }
  }
})
