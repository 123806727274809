import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'


export default {
  __name: 'NeueToggle',
  props: ['modelValue', 'name', 'description', 'disabled', 'compact', 'disabledTitle'],
  emits: ['update:modelValue', 'change'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(SwitchGroup), {
    as: "div",
    class: _normalizeClass(["flex items-center", props.compact ? 'gap-6' : 'justify-between'])
  }, {
    default: _withCtx(() => [
      (props.name || props.description)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["flex flex-col mr-1", props.compact ? '' : 'flex-grow'])
          }, [
            _createVNode(_unref(SwitchLabel), {
              as: "span",
              class: _normalizeClass(["text-sm font-medium", props.disabledTitle ? 'text-gray-500' : 'text-gray-900']),
              passive: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.name), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_unref(SwitchDescription), {
              as: "span",
              class: "text-sm text-gray-500 mr-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.description), 1)
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true),
      _createVNode(_unref(Switch), {
        "model-value": props.modelValue,
        disabled: props.disabled,
        class: _normalizeClass([
        props.modelValue ? 'bg-brand-600' : 'bg-gray-200',
        props.disabled ? 'cursor-wait' : 'cursor-pointer',
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'
      ]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = 
        e => {
          emit('update:modelValue', e)
          emit('change', e)
        }
      )
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            "aria-hidden": "true",
            class: _normalizeClass([
          props.modelValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        ])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["model-value", "disabled", "class"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

}