import script from "./StandardLayout.vue?vue&type=script&setup=true&lang=js"
export * from "./StandardLayout.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import Toast from "@/components/neue/Toast.vue";
script.components = Object.assign({}, { Toast }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { Toast }, script.__o.components);}