<template>
  <SecondaryNav>
    <template #sidebar>
      <ConversationsMenu />
    </template>
    <template #top>
      <SecondaryTop
        :parent="{ name: 'Messages', link: '/messages' }"
        :text="name"
        :editable="true"
        :sections="[
          { name: 'Messages', active: false, action: () => router.push('/messages/' + route.params.id) },
          { name: 'Profile', active: true }
        ]"
        @change="saveName"
      />
    </template>
    <template #main>
      <div class="absolute bottom-0 top-0 pt-8 w-full h-auto bg-gray-brand-100 overflow-auto">
        <NeueOrderModal :is-open="newOrderModalOpen" :pair="route.params.id" @close-modal="newOrderModalOpen = false" />

        <!-- Account credit modal -->
        <TheModal
          v-if="local.modal.type === 'creditModal'"
          :is-open="local.modal.type === 'creditModal'"
          modal-title="Add account credit"
          @close-modal="local.modal.type = ''"
        >
          <template #main>
            <Form v-slot="{ errors }" class="grid grid-cols-12 gap-4" @submit.prevent="saveCreditModal()">
              <div class="col-span-12">
                <FormLabel for="accountCredit" label="Credit amount" />
                <div class="flex rounded-md shadow-sm">
                  <span
                    class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                  >
                    $
                  </span>
                  <Field
                    id="accountCredit"
                    v-model="local.modal.content.accountCreditAmount"
                    :rules="fieldIsRequired"
                    oninput="this.value = this.value.replace(/[^0-9\.\-]/g,'')"
                    type="text"
                    name="accountCredit"
                    inputmode="text"
                    pattern="[0-9]*.?[0-9]*"
                    class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  />
                </div>
                <InputError v-show="errors.accountCredit" />
                <div v-if="local.modal.content.onSubmitMessage" class="mt-4 text-red-600">
                  <div class="text-sm font-medium">
                    {{ local.modal.content.onSubmitMessage }}
                  </div>
                  <div v-if="local.modal.content.onSubmitLog" class="mt-1 text-sm">
                    {{ local.modal.content.onSubmitLog }}
                  </div>
                </div>
              </div>
            </Form>
          </template>
          <template #footer>
            <button class="btn-primary justify-center mr-3" type="button" @click="saveCreditModal()">Save</button>
            <button class="btn-ghost justify-center" type="button" @click="resetModalContent()">Cancel</button>
          </template>
        </TheModal>

        <!-- Pass selection confirmation modal -->
        <TheModal
          v-if="local.modal.type === 'confirmPassSelection'"
          :is-open="local.modal.type === 'confirmPassSelection'"
          :modal-title="`Assign ${local.pendingPass?.name} to ${name}`"
          @close-modal="resetModalContent"
        >
          <template #main>
            <div class="mb-4">
              <PassBenefits :membership-pass="membershipPass" />
              <p v-if="local.showConfirmWarning" class="mt-4 flex items-start">
                <svg class="w-6 h-6 mr-2 text-red-600" viewBox="0 0 91.8 120" fill="currentColor">
                  <path
                    d="M45.9,3.6c-23.5,0-42.5,19-42.5,42.5c0,23.5,19,42.5,42.5,42.5c23.5,0,42.5-19,42.5-42.5  C88.4,22.7,69.4,3.6,45.9,3.6z M43.7,21.1h4.3c0.5,0,0.9,0.4,0.9,0.9l-0.6,34.5c0,0.5-0.4,0.9-0.9,0.9h-3c-0.5,0-0.9-0.4-0.9-0.9  L42.8,22C42.8,21.5,43.2,21.1,43.7,21.1z M48.6,71.2c-0.8,0.8-1.7,1.1-2.7,1.1c-1,0-1.9-0.3-2.6-1c-0.8-0.7-1.3-1.8-1.3-2.9  c0-1,0.4-1.9,1.1-2.7c0.7-0.8,1.8-1.2,2.9-1.2c1.2,0,2.2,0.5,3,1.4c0.5,0.6,0.8,1.3,0.9,2.1C49.9,69.3,49.5,70.3,48.6,71.2z"
                  />
                </svg>
                <span class="text-sm text-gray-600">
                  Assigning the {{ local.pendingPass?.name }} would automatically charge {{ name }}'s account. Are you
                  sure you want to continue?
                </span>
              </p>
            </div>
          </template>
          <template #footer>
            <button class="btn-ghost justify-center" @click="cancelPassSelection">Cancel</button>
            <button class="btn-primary justify-center" @click="handleConfirmClick">
              {{ local.showConfirmWarning ? 'Confirm' : 'Assign' }}
            </button>
          </template>
        </TheModal>

        <div class="mx-auto px-4 max-w-6xl sm:px-6 lg:px-8">
          <SectionHeader heading="Overview" />
          <div class="grid gap-5 grid-cols-1 mt-2 md:grid-cols-2">
            <info-card icon="ScaleIcon">
              <template #list>
                <h3 class="text-lg font-bold text-gray-700">Membership</h3>
                <dl v-show="!local.status.customer.isLoading" class="mt-2 mb-5">
                  <dt class="info-card__title">Membership tier</dt>
                  <dd>
                    <div class="info-card__text">
                      <SelectInput
                        :options="membershipTiers?.map(x => ({ name: x.attributes.tier_name, value: x.id }))"
                        :model-value="membershipTier?.id"
                        @update:modelValue="setTier"
                      />
                    </div>
                  </dd>
                  <template v-if="cartSubscription">
                    <dt class="info-card__title">Next subscription production date</dt>
                    <dd>
                      <div class="info-card__text">
                        <div v-if="!local.editingEffective" class="flex items-center">
                          {{ mediumFormat(serverDayToDateObject(cartSubscription?.attributes.next_upcoming_date)) }}

                          <PencilIcon class="ml-2 w-4 h-4 cursor-pointer" @click="local.editingEffective = true" />
                        </div>
                        <div v-if="local.editingEffective" class="flex items-center">
                          <DatePicker v-model="local.effective" mode="date" />
                          <CheckIcon class="ml-4 w-6 h-6 cursor-pointer" @click="saveEffective" />
                        </div>
                      </div>
                    </dd>

                    <dt class="info-card__title">Subscription interval</dt>
                    <dd>
                      <div class="info-card__text">
                        <SelectInput
                          :options="
                            intervalOptions?.map(x => ({ name: x.attributes.display_description, value: x.id }))
                          "
                          :model-value="cartSubscription.relationships.interval_cart_subscription_setting.data?.id"
                          @update:modelValue="setInterval"
                        />
                      </div>
                    </dd>

                    <dt class="info-card__title">Default store</dt>
                    <dd>
                      <div class="info-card__text">
                        <SelectInput
                          :options="stores?.map(x => ({ name: x.attributes.store_name, value: x.id }))"
                          :model-value="cartSubscription.relationships.store.data?.id"
                          @update:modelValue="setStore"
                        />
                      </div>
                    </dd>
                  </template>
                  <template v-if="passOptions.length">
                    <dt class="info-card__title">Pass</dt>
                    <dd>
                      <div class="info-card__text">
                        <template
                          v-if="
                            membershipPassSelection &&
                            membershipPassSelection.attributes.membership_pass_id.toString() ==
                              membershipPass?.id.toString()
                          "
                        >
                          {{ membershipPass.attributes.name }}
                        </template>
                        <a
                          v-else
                          href="#"
                          class="text-blue-600 hover:text-blue-800"
                          @click.prevent="handlePassSelect(passOptions[0]?.value)"
                        >
                          Add to {{ passOptions[0]?.name }}
                        </a>
                      </div>
                    </dd>
                  </template>

                  <template v-if="cartSubscription">
                    <template v-if="packages.length">
                      <dt class="info-card__title">Package</dt>
                      <dd>
                        <div class="info-card__text">
                          <SelectInput
                            :options="packages?.map(x => ({ name: x.attributes.package_name, value: x.id }))"
                            :model-value="cartSubscription.relationships.package.data?.id"
                            @update:modelValue="setPackage"
                          />
                        </div>
                      </dd>
                    </template>
                  </template>

                  <dt v-show="data.memberSince" class="info-card__title">
                    {{ membershipTier?.attributes?.tier_name }} Member
                  </dt>
                  <dd v-if="membershipTierSelection?.attributes.created_at">
                    <div class="info-card__text">
                      Since {{ mediumFormat(new Date(membershipTierSelection?.attributes.created_at)) }}
                    </div>
                  </dd>

                  <dt v-show="data.memberSince" class="info-card__title">Contact since</dt>
                  <dd>
                    <div class="info-card__text">{{ data.memberSince }}</div>
                  </dd>
                </dl>
                <div v-show="local.status.customer.isLoading" class="flex justify-center flex-col mb-10 mt-4">
                  <div class="mx-auto h-5 w-5 mt-5">
                    <BasicSpinner color="gray-900" />
                  </div>
                </div>
              </template>
            </info-card>
            <info-card icon="IdentificationIcon">
              <template #list>
                <h3 class="text-lg font-bold text-gray-700">Profile</h3>
                <dl v-show="!local.status.customer.isLoading" class="mt-2 mb-5">
                  <dd>
                    <div class="info-card__text">
                      <a :href="data.profileLink" target="_blank">Open contact's account portal</a>
                    </div>
                  </dd>

                  <dt v-show="data.email" class="info-card__title">Email</dt>
                  <dd v-show="data.email">
                    <div class="info-card__text">{{ data.email }}</div>
                  </dd>
                  <dt v-show="data.phoneFormatted" class="info-card__title">Phone</dt>
                  <dd v-show="data.phoneFormatted">
                    <div class="info-card__text flex items-center">
                      <span class="mr-1 mt-1">{{ data.phoneFormatted }}</span>
                      <button
                        v-tippy="{
                          content: local.phoneSupportCopy,
                          interactive: true,
                          maxWidth: 200,
                          placement: 'right'
                        }"
                        class="text-gray-400 hover:text-blue-500"
                      >
                        <InformationCircleIcon class="inline-block w-4 h-4" />
                      </button>
                    </div>
                  </dd>
                  <dt v-show="data.code" class="info-card__title">Referral code</dt>
                  <dd v-show="data.code">
                    <div class="info-card__text">
                      <div v-if="!local.editingCode" class="flex items-center">
                        {{ data.code?.attributes.code }}
                        <PencilIcon class="ml-2 w-4 h-4 cursor-pointer" @click="local.editingCode = true" />
                      </div>
                      <div v-if="local.editingCode" class="flex items-center">
                        <InputField v-model="local.code" @keyup.enter="saveCode" />
                        <CheckIcon class="ml-2 w-6 h-6 cursor-pointer" @click="saveCode" />
                      </div>
                    </div>
                  </dd>

                  <dt class="info-card__title">Account credit</dt>
                  <dd>
                    <div class="info-card__text">
                      {{ data.accountCredit }}
                    </div>
                  </dd>
                  <dt class="info-card__title">Total Spent</dt>
                  <dd>
                    <div class="info-card__text">
                      {{ data.lifetimeSpend }}
                    </div>
                  </dd>

                  <dt class="info-card__title">Price list</dt>
                  <dd>
                    <div class="info-card__text">
                      <SelectInput
                        @update:modelValue="setPriceList"
                        :options="[
                          { name: '(None)', value: null },
                          ...priceLists.map(x => ({ name: x.attributes.price_list_name, value: x.id }))
                        ]"
                        :model-value="conversationUserPair?.relationships.price_list.data?.id"
                      />
                    </div>
                  </dd>
                </dl>
                <div v-show="local.status.customer.isLoading" class="flex justify-center flex-col mb-10 mt-4">
                  <div class="mx-auto h-5 w-5 mt-5">
                    <BasicSpinner color="gray-900" />
                  </div>
                </div>
              </template>
              <template #footer>
                <button class="btn-primary -xs" @click="local.modal.type = 'creditModal'">Add credit</button>
              </template>
            </info-card>
          </div>

          <div class="w-full mt-4">
            <PassInfoCard
              v-if="
                membershipTier &&
                membershipPass &&
                membershipPassSelection &&
                membershipPassSelection.attributes.membership_pass_id.toString() === membershipPass.id.toString()
              "
              :membership-tier="membershipTier"
              :membership-pass="membershipPass"
              :is-loading="local.status.customer.isLoading"
              :customer-name="name"
              :conversation-user-pair-id="route.params.id"
              @cancel-pass="cancelPass"
            />
          </div>
        </div>

        <!-- <CustomerAddresses
      :conversation-user-pair-id="$route.params.id"
      class="mx-auto mb-5 mt-8 px-4 max-w-6xl sm:px-6 lg:px-8"
    /> -->

        <TabGroup>
          <TabList>
            <div class="mx-auto px-4 max-w-6xl sm:px-6 lg:px-8 mb-5 mt-12 flex justify-between">
              <div class="space-x-1">
                <Tab v-slot="{ selected }" as="template">
                  <button :class="selected ? 'btn-primary' : 'btn-ghost'" @click="dropFilterSelected">Orders</button>
                </Tab>
                <Tab v-slot="{ selected }" as="template">
                  <button :class="selected ? 'btn-primary' : 'btn-ghost'" @click="dropFilterSelected">Carts</button>
                </Tab>
              </div>
              <NeueButton @click="newOrderModalOpen = true">+ Order</NeueButton>
            </div>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div class="mx-auto max-w-6xl px-4">
                <BottlesList
                  :bottles="data.orders"
                  :tab="'contacts'"
                  :show-actions="true"
                  :is-loading="local.status.orders.isLoading"
                  :is-empty="local.status.orders.isEmpty"
                  :has-error="local.status.orders.hasError"
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div class="mx-auto max-w-6xl px-4">
                <BottlesList
                  :bottles="data.carts"
                  :tab="'contacts'"
                  :show-actions="true"
                  :is-loading="local.status.carts.isLoading"
                  :is-empty="local.status.carts.isEmpty"
                  :has-error="local.status.carts.hasError"
                />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <!-- TODO(alicja): style those four -->
        <div class="mx-auto px-4 max-w-6xl sm:px-6 lg:px-8">
          <profile-tags :conversation-user-pair-id="$route.params.id" class="mb-5 mt-8" />

          <profile-notes :conversation-user-pair-id="$route.params.id" class="mb-5 mt-8" />

          <profile-tax :conversation-user-pair-id="$route.params.id" class="mb-5 mt-8" />

          <profile-attrs :conversation-user-pair-id="$route.params.id" class="mb-5 mt-8" />

          <profile-danger-zone :conversation-user-pair-id="$route.params.id" class="mb-5 mt-8" />
        </div>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import 'tippy.js/dist/tippy.css'

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { CheckIcon, PencilIcon } from '@heroicons/vue/20/solid'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import ConversationsMenu from '@/components/customers/ConversationsMenu.vue'
import PassInfoCard from '@/components/customers/PassInfoCard.vue'
import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships, mapType } from '@/store/mappers'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    InformationCircleIcon,
    Field,
    Form,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    ConversationsMenu,
    PencilIcon,
    CheckIcon,
    DatePicker,
    PassInfoCard
  },
  setup() {
    const newOrderModalOpen = ref(false)
    const store = useStore()

    const name = computed(
      () => mapRelationship({ type: 'conversation_user_pair', id: route.params.id })?.attributes.display_name
    )

    const saveName = name => {
      api.call('PUT', `conversation_user_pairs/${route.params.id}`, {
        conversation_user_pair: {
          display_name: name
        }
      })
    }

    const saveCode = () => {
      api
        .call('PUT', `conversation_user_pairs/${route.params.id}?included=referral_discount_codes`, {
          conversation_user_pair: {
            referral_discount_codes_attributes: [{ id: data.value.code.id, code: local.value.code }]
          }
        })
        .then(response => {
          data.value.code = mapRelationship(response.data.data.relationships.referral_discount_codes.data?.[0])
        })
      local.value.editingCode = false
    }

    const saveEffective = () => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: {
          effective_starting_date: dateObjectToServerDay(local.value.effective)
        }
      })
      local.value.editingEffective = false
    }

    const route = useRoute()
    const router = useRouter()

    const data = ref({
      accountCredit: '',
      lifetimeSpend: '',
      memberSince: '',
      phoneRaw: '',
      phoneFormatted: '',
      email: '',
      sortedOrders: [],
      sortedCarts: [],
      orders: computed(() => mapRelationships(data.value.sortedOrders)),
      carts: computed(() => mapRelationships(data.value.sortedCarts))
    })

    const local = ref({
      status: {
        customer: {
          isLoading: false
        },
        orders: {
          isLoading: false,
          isEmpty: false,
          hasError: false
        },
        carts: {
          isLoading: false,
          isEmpty: false,
          hasError: false
        }
      },
      modal: {
        type: '',
        content: {}
      },
      phoneSupportCopy: '<a href="mailto:team@bottle.com">Contact Bottle</a> to change the contact\'s phone number',
      code: null,
      editingCode: false,
      effective: null,
      editingEffective: false,
      selectedPassId: null,
      pendingPass: null,
      showConfirmWarning: false
    })

    watch(
      () => local.value.code,
      () => {
        if (!local.value.code) return
        local.value.code = local.value.code
          .toUpperCase()
          .replace(/\s/g, '-')
          .replace(/[^A-Za-z0-9_-]*$/g, '')
      }
    )

    const getCustomers = () => {
      local.value.status.customer.isLoading = true
      api.call('GET', `conversation_user_pairs/${route.params.id}/customer`).then(response => {
        const attrs = response.data.data.attributes

        data.value.accountCredit = attrs?.account_credit?.format ?? null
        data.value.lifetimeSpend = attrs?.lifetime_spend?.format ?? null
        data.value.phoneRaw = attrs?.phone_number?.raw ?? null
        data.value.phoneFormatted = attrs?.phone_number?.formatted ?? null
        data.value.email = attrs?.email ?? null
        data.value.profileLink = attrs?.profile_link ?? null

        local.value.status.customer.isLoading = false
      })
    }

    const getConversationUserPairs = () => {
      api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}?included=referral_discount_codes,current_cart_subscription,current_cart_subscription.possible_packages,membership_tier,membership_tier.membership_pass,membership_pass_selection`
        )
        .then(response => {
          const attrs = response.data.data.attributes

          data.value.code = mapRelationship(response.data.data.relationships.referral_discount_codes.data?.[0])
          local.value.effective = serverDayToDateObject(cartSubscription.value?.attributes.effective_starting_date)
          local.value.code = data.value.code?.attributes.code

          data.value.memberSince = attrs.created_at
            ? new Intl.DateTimeFormat('en-US', {
                dateStyle: 'medium'
              }).format(Date.parse(attrs.created_at))
            : null
        })
    }

    const getOrders = () => {
      local.value.status.orders.isLoading = true
      const includes = [
        'checkout',
        'cart',
        'cart.cart_items',
        'cart.cart_items.product',
        'store',
        'conversation_user_pair',
        'fulfillment_slot',
        'membership_tier',
        'distribution_list',
        'bottle_cycle',
        'bottle_payment'
      ]

      return api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}/orders?included=${includes.join(
            ','
          )}&sort_by_field=bottle_payments.created_at&sort_direction=desc`
        )
        .then(r => {
          data.value.sortedOrders = r.data.data
          local.value.status.orders.isLoading = false
          if (Object.keys(r.data.data).length) local.value.status.orders.isEmpty = false
        })
        .catch(() => (local.value.status.orders.hasError = true))
    }

    const getCarts = () => {
      local.value.status.carts.isLoading = true
      const includes = [
        'checkout',
        'store',
        'conversation_user_pair',
        'fulfillment_slot',
        'membership_tier',
        'distribution_list',
        'bottle_cycle',
        'bottle_payment'
      ]

      return api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}/carts?included=${includes.join(
            ','
          )}&sort_by_field=bottle_payments.created_at&sort_direction=desc`
        )
        .then(r => {
          data.value.sortedCarts = r.data.data
          local.value.status.carts.isLoading = false
          if (Object.keys(r.data.data).length) local.value.status.carts.isEmpty = false
        })
        .catch(() => (local.value.status.carts.hasError = true))
    }

    const saveCreditModal = () => {
      const accountCreditAmountCents = local.value.modal.content.accountCreditAmount * 100
      api
        .call(
          'POST',
          `conversation_user_pairs/${route.params.id}/account_credits`,
          {
            account_credit: {
              value: accountCreditAmountCents
            }
          },
          false
        )
        .then(response => {
          const attrs = response.data.data.attributes
          data.value.accountCredit = attrs?.account_credit?.format ?? null

          resetModalContent()
        })
        .catch(error => {
          local.value.modal.content.onSubmitMessage = 'Something went wrong!'
          local.value.modal.content.onSubmitLog = error
        })
    }

    const resetModalContent = () => {
      local.value.modal.type = ''
      local.value.modal.content = {}
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const conversationUserPair = computed(() =>
      mapRelationship({ type: 'conversation_user_pair', id: route.params.id })
    )

    const membershipPassSelection = computed(() => {
      return conversationUserPair.value?.relationships?.membership_pass_selection?.data
        ? mapRelationship(conversationUserPair.value?.relationships?.membership_pass_selection?.data)
        : null
    })

    const membershipTierSelection = computed(() => {
      return conversationUserPair.value?.relationships?.membership_tier_selection?.data
        ? mapRelationship(conversationUserPair.value?.relationships?.membership_tier_selection?.data)
        : null
    })

    const membershipTier = computed(() => {
      return conversationUserPair.value?.relationships?.membership_tier?.data
        ? mapRelationship(conversationUserPair.value?.relationships?.membership_tier?.data)
        : null
    })

    const membershipPass = computed(() => {
      return membershipTier.value?.relationships?.membership_pass?.data
        ? mapRelationship(membershipTier.value?.relationships?.membership_pass?.data)
        : null
    })

    const intervalOptions = computed(() =>
      mapRelationships(membershipTier.value?.relationships.cart_subscription_settings.data).filter(
        x => x.attributes.setting_name === 'interval'
      )
    )

    const packages = computed(() => mapRelationships(cartSubscription.value?.relationships.possible_packages.data))

    const membershipTiers = computed(() => mapType('membership_tier'))

    const priceLists = computed(() => mapType('price_list'))

    const passOptions = computed(() => {
      if (!membershipTier.value?.relationships?.membership_pass?.data) return []
      const passData = membershipTier.value.relationships.membership_pass.data
      const mappedPass = mapRelationship(passData)
      return [
        {
          name: mappedPass?.attributes?.name,
          value: mappedPass?.id
        }
      ]
    })

    const mediumFormat = new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium'
    }).format

    const cartSubscription = computed(() =>
      mapRelationship(conversationUserPair.value?.relationships.current_cart_subscription.data)
    )

    const stores = computed(() =>
      mapRelationships(membershipTier.value?.relationships.cart_subscription_store_options.data)
    )

    const getMembershipTiers = () => {
      api.call(
        'GET',
        'membership_tiers?included=cart_subscription_settings,cart_subscription_store_options,membership_pass,membership_pass.membership_pass_rewards'
      )
    }

    const setTier = id => {
      api
        .call(
          'POST',
          `conversation_user_pairs/${route.params.id}/membership_tier_selections?included=current_cart_subscription,membership_tier_selection`,
          {
            membership_tier_selection: { membership_tier_id: id }
          }
        )
        .then(() => {
          local.value.effective = serverDayToDateObject(cartSubscription.value?.attributes.effective_starting_date)
        })
    }

    const getPriceLists = () => {
      api.call('GET', 'price_lists?included=none')
    }

    const setPriceList = id => {
      api.call('PUT', `conversation_user_pairs/${route.params.id}?included=none`, {
        conversation_user_pair: { price_list_id: id }
      })
    }

    const setInterval = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { interval_cart_subscription_setting_id: id }
      })
    }

    const setStore = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { store_id: id }
      })
    }

    const setPackage = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { package_id: id }
      })
    }

    const handlePassSelect = id => {
      local.value.selectedPassId = id
      local.value.pendingPass = passOptions.value.find(p => p.value === id)
      local.value.modal.type = 'confirmPassSelection'
    }

    const handleConfirmClick = () => {
      if (!local.value.showConfirmWarning) {
        local.value.showConfirmWarning = true
        return
      }
      confirmPassSelection()
    }

    const confirmPassSelection = () => {
      const selectedPassId = local.value.selectedPassId
      local.value.modal.type = ''
      local.value.selectedPassId = null
      local.value.pendingPass = null
      local.value.showConfirmWarning = false
      local.value.modal.content = {}

      // Hannes confirm if you want include these.
      api.call(
        'POST',
        `conversation_user_pairs/${route.params.id}/membership_passes?included=membership_pass_selection`,
        {
          membership_pass_selection: { membership_pass_id: selectedPassId }
        }
      )
    }

    const cancelPassSelection = () => {
      local.value.selectedPassId = null
      local.value.pendingPass = null
      local.value.showConfirmWarning = false
      local.value.modal.type = ''
    }

    const cancelPass = passId => {
      api
        .call(
          'DELETE',
          `/conversation_user_pairs/${route.params.id}/membership_passes/${passId}`,
          {},
          { delete: false }
        )
        .then(() => {
          store.commit('DESTROY_OBJECT_WITHOUT_ID', 'membership_pass_payment_transaction')
        })
    }

    onMounted(() => {
      getConversationUserPairs()
      getCustomers()
      getCarts()
      getOrders()
      getMembershipTiers()
      getPriceLists()
    })

    return {
      data,
      local,
      saveCreditModal,
      resetModalContent,
      fieldIsRequired,
      mediumFormat,
      membershipTierSelection,
      membershipTier,
      membershipPass,
      membershipPassSelection,
      passOptions,
      route,
      name,
      saveName,
      saveCode,
      saveEffective,
      setTier,
      membershipTiers,
      cartSubscription,
      serverDayToDateObject,
      intervalOptions,
      setInterval,
      setStore,
      newOrderModalOpen,
      packages,
      setPackage,
      conversationUserPair,
      priceLists,
      setPriceList,
      stores,
      router,
      handlePassSelect,
      handleConfirmClick,
      confirmPassSelection,
      cancelPassSelection,
      cancelPass
    }
  }
})
</script>
