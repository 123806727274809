import _ from 'lodash'

const objectRefreshed = {} // not in Vuex state for perf, we don't need it to be reactive

export default {
  objectRefreshed,
  RECEIVE_OBJECT(state, object) {
    function set(obj) {
      state.objects[obj.type] = state.objects[obj.type] || {}
      state.objects[obj.type][obj.id] = obj
      objectRefreshed[obj.type] = objectRefreshed[obj.type] || {}
      objectRefreshed[obj.type][obj.id] = Date.now()
    }

    if (object.data) {
      if (Array.isArray(object.data)) {
        object.data.forEach(x => set(x))
      } else {
        set(object.data)
      }
    }
    if (object.included) {
      object.included.forEach(x => set(x))
    }
  },
  DESTROY_OBJECT(state, object) {
    const obj = object.data
    delete state.objects[obj.type][obj.id]
  },
  DESTROY_OBJECT_WITHOUT_ID(state, type) {
    state.objects[type] = {}
  },
  INC_SAVING(state) {
    state.savingCount++
  },
  DEC_SAVING(state) {
    state.savingCount--
  },
  TOGGLE_OMNI(state) {
    state.omniSearchOpen = !state.omniSearchOpen
  },
  SET_LOADING_STATE(state, resourceName) {
    if (resourceName) {
      state.status[resourceName].loading = true
    }
  },
  SET_LOADED_STATE(state, resourceName) {
    state.status[resourceName].loading = false
  },
  SET_ONGOING_CALL(state, call) {
    state.ongoingCall = call
  },
  SET_LOGGED_IN_USER(state, user) {
    state.loggedInUser = user.data
  },
  SET_HIDE_NUMBERS(state, hideNumbers) {
    state.hideNumbers = hideNumbers
  },
  GET_USER(state, user) {
    if (user.data) {
      state.user = user.data.attributes
    }
  },

  SET_AUTH_TOKEN(state, token) {
    state.authToken = token
  },
  SET_PUSHER(state, pusher) {
    state.pusher = pusher
  },
  ADD_PENDING_MESSAGE(state, message) {
    state.pendingMessages[message.key] = { body: message.body, src: message.src }
  },
  REMOVE_PENDING_MESSAGE(state, key) {
    delete state.pendingMessages[key]
  },
  SIGNUP_PROPERTY_VALUES(state, object) {
    if (!object) {
      localStorage.removeItem('signupPropertyValues')
      return
    }
    const signupPropertyValues = JSON.parse(localStorage.getItem('signupPropertyValues')) || { nextPage: 1 }
    const currentNextPage = signupPropertyValues['nextPage'] || 1
    const objectNextPage = object['nextPage']
    Object.keys(object).forEach(key => {
      signupPropertyValues[key] = object[key]
    })
    if (objectNextPage > currentNextPage) {
      signupPropertyValues['nextPage'] = objectNextPage
    } else {
      signupPropertyValues['nextPage'] = currentNextPage
    }
    localStorage.setItem('signupPropertyValues', JSON.stringify(signupPropertyValues))
  }
}
