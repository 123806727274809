<template>
  <div class="w-full mt-4">
    <info-card icon="TicketIcon">
      <template #list>
        <h3 class="text-lg font-bold text-gray-700">{{ membershipPass.attributes.name }}</h3>
        <dl v-show="!isLoading" class="mt-2 mb-5">
          <template v-if="membershipPass">
            <div class="flex items-center justify-between">
              <div class="max-w-[50%]">
                <PassBenefits :membership-pass="membershipPass" />
              </div>
              <div class="flex gap-2">
                <button class="btn-primary -xs" @click="showCancelModal = true">Cancel Pass</button>
                <button class="btn-primary -xs" @click="openRefundModal">Refund Pass</button>
              </div>
            </div>
          </template>
        </dl>
        <div v-show="isLoading" class="flex justify-center flex-col mb-10 mt-4">
          <div class="mx-auto h-5 w-5 mt-5">
            <BasicSpinner color="gray-900" />
          </div>
        </div>
      </template>
    </info-card>

    <TheModal
      :is-open="showCancelModal"
      :modal-title="`Are you sure you want to cancel the ${membershipPass.attributes.name} for ${customerName}?`"
      @close-modal="showCancelModal = false"
    >
      <template #footer>
        <button class="btn-ghost justify-center mr-3" @click="showCancelModal = false">No I'm not</button>
        <button class="btn-primary justify-center" @click="handleCancelConfirm">Yes, I'm sure</button>
      </template>
    </TheModal>

    <TheModal
      v-model:is-open="showRefundModal"
      :modal-title="`Refund the ${membershipPass.attributes.name} for ${customerName}`"
    >
      <template #main>
        <p class="text-sm text-gray-600">Payments {{ customerName }} have made towards this pass.</p>
        <div class="mt-4">
          <MultiSelectInput
            v-model="selectedPayments"
            :options="paymentOptions"
            placeholder="Select payments to refund"
            :showSelectAll="paymentOptions.length > 1"
          />
        </div>
      </template>
      <template #footer>
        <button class="btn-ghost justify-center" @click="showRefundModal = false">Cancel</button>
        <button
          class="btn-primary justify-center disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="!selectedPayments.length"
          @click="handleRefundConfirm"
        >
          Refund
        </button>
      </template>
    </TheModal>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import InfoCard from '@/components/InfoCard.vue'
import BasicSpinner from '@/components/BasicSpinner.vue'
import TheModal from '@/components/TheModal.vue'
import MultiSelectInput from '@/components/neue/MultiSelectInput.vue'
import PassBenefits from '@/components/customers/PassBenefits.vue'
import api from '@/store/api'
import { mapType } from '@/store/mappers'

export default defineComponent({
  name: 'PassInfoCard',
  components: {
    InfoCard,
    BasicSpinner,
    TheModal,
    MultiSelectInput,
    PassBenefits
  },
  emits: ['cancel-pass', 'refund-pass'],
  props: {
    membershipTier: {
      type: Object,
      required: true
    },
    membershipPass: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    customerName: {
      type: String,
      required: true
    },
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const showCancelModal = ref(false)
    const showRefundModal = computed({
      get: () => refundModalOpen.value,
      set: val => {
        refundModalOpen.value = val
        if (!val) {
          selectedPayments.value = []
        }
      }
    })

    const selectedPayments = ref([])

    const paymentTransactions = computed(() => mapType('membership_pass_payment_transaction'))

    const getPaymentTransactions = () => {
      api.call('GET', `conversation_user_pairs/${props.conversationUserPairId}/membership_passes/payment_transactions`)
    }

    const paymentOptions = computed(() => {
      return paymentTransactions.value.map(transaction => ({
        name: `${transaction.attributes.transaction_date} - ${transaction.attributes.amount.format}${
          transaction.attributes.is_refunded ? ' (Refunded)' : ''
        }`,
        value: transaction.id,
        disabled: transaction.attributes.is_refunded
      }))
    })

    const handleCancelConfirm = () => {
      emit('cancel-pass', props.membershipPass.id)
      showCancelModal.value = false
    }

    const handleRefundConfirm = () => {
      selectedPayments.value.forEach(paymentId => {
        api.call(
          'POST',
          `conversation_user_pairs/${props.conversationUserPairId}/membership_passes/payment_transactions/${paymentId}/refund`,
          {
            membership_pass_selection: {
              membership_pass_id: props.membershipPass.id
            }
          }
        )
      })
      showRefundModal.value = false
    }

    const refundModalOpen = ref(false)

    const openRefundModal = () => {
      getPaymentTransactions()
      refundModalOpen.value = true
    }

    return {
      showCancelModal,
      showRefundModal,
      handleCancelConfirm,
      handleRefundConfirm,
      selectedPayments,
      paymentOptions,
      openRefundModal
    }
  }
})
</script>
