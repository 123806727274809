import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fixed z-50 top-4 left-1/2 -translate-x-1/2 flex items-center px-4 py-3 bg-white shadow-lg rounded-lg transition-opacity duration-200" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2 text-sm text-gray-900" }

import { ref, onMounted, watch } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'


export default {
  __name: 'Toast',
  props: {
  message: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'success',
    validator: value => ['success', 'error', 'info'].includes(value)
  },
  duration: {
    type: Number,
    default: 5000
  }
},
  setup(__props) {

const props = __props

const show = ref(false)

const showToast = () => {
  // First ensure toast is hidden
  show.value = false

  // Force a DOM reflow
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      show.value = true
      if (props.duration > 0) {
        setTimeout(() => {
          show.value = false
        }, props.duration)
      }
    })
  })
}

onMounted(() => {
  showToast()
})

watch(
  () => props.message,
  () => {
    if (props.message) {
      showToast()
    }
  }
)

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    appear: "",
    show: show.value,
    as: "template",
    enter: "transform ease-out duration-300 transition",
    "enter-from": "translate-y-[-100%] opacity-0",
    "enter-to": "translate-y-0 opacity-100",
    leave: "transition ease-in duration-100",
    "leave-from": "opacity-100",
    "leave-to": "opacity-0"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (__props.type === 'success')
            ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                key: 0,
                class: "h-5 w-5 text-green-500"
              }))
            : _createCommentVNode("", true),
          (__props.type === 'error')
            ? (_openBlock(), _createBlock(_unref(ExclamationCircleIcon), {
                key: 1,
                class: "h-5 w-5 text-red-500"
              }))
            : _createCommentVNode("", true),
          (__props.type === 'info')
            ? (_openBlock(), _createBlock(_unref(InformationCircleIcon), {
                key: 2,
                class: "h-5 w-5 text-blue-500"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(__props.message), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = $event => (show.value = false)),
            class: "ml-4 text-gray-400 hover:text-gray-600 focus:outline-none"
          }, [
            _createVNode(_unref(XMarkIcon), { class: "h-4 w-4" })
          ])
        ])
      ], 512), [
        [_vShow, show.value]
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

}