import { ref } from 'vue'

const visible = ref(false)
const message = ref('')
const type = ref('success')
const duration = ref(5000)
const key = ref(0)

export function useToast() {
  const showToast = (newMessage, options = {}) => {
    message.value = newMessage
    type.value = options.type || 'success'
    duration.value = options.duration ?? 5000
    visible.value = true
    key.value++
  }

  const hide = () => {
    visible.value = false
  }

  return {
    visible,
    message,
    type,
    duration,
    key,
    showToast,
    hide
  }
}
