
import { defineComponent, computed } from 'vue'
import { mapRelationships } from '@/store/mappers'

export default defineComponent({
  name: 'PassBenefits',
  props: {
    membershipPass: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const passRewards = computed(
      () => mapRelationships(props.membershipPass?.relationships?.membership_pass_rewards?.data) || []
    )

    const sortedPassRewards = computed(() => {
      if (!passRewards.value) return []

      // Define the order of reward types
      const orderMap = {
        ImmediateMembershipPassReward: 1,
        AccountCreditMembershipPassReward: 2,
        FulfillmentMembershipPassReward: 3
      }

      // Sort rewards based on their type
      return [...passRewards.value].sort((a, b) => orderMap[a.attributes.type] - orderMap[b.attributes.type])
    })

    const formatReward = reward => {
      if (!reward) return { type: null, text: '', terms_and_conditions_text: '' }

      const attrs = reward.attributes

      const formatAmount = amount => {
        if (!amount) return ''
        return amount.endsWith('.00') ? amount.slice(0, -3) : amount
      }

      const getOrdinalSuffix = n => {
        const s = ['th', 'st', 'nd', 'rd']
        const v = n % 100
        return s[(v - 20) % 10] || s[v] || s[0]
      }

      const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      switch (attrs.type) {
        case 'ImmediateMembershipPassReward':
          return {
            type: attrs.type,
            text: `${formatAmount(attrs.credit_amount?.format)} off this order for signing up!`
          }

        case 'AccountCreditMembershipPassReward': {
          const amount = formatAmount(attrs.credit_amount?.format)
          const interval = attrs.credit_interval
          const awardDay = attrs.credit_award_day
          const awardDate = attrs.credit_award_date
          const expiryValue = attrs.expiry_value
          const expiryType = attrs.expiry_type
          const autoApply = attrs.auto_apply

          let timing = ''
          if (interval === 'monthly' && awardDate) {
            timing = `on the ${awardDate}${getOrdinalSuffix(awardDate)} of each month`
          } else if (interval === 'weekly' && awardDay) {
            timing = `every ${capitalizeFirstLetter(awardDay)}`
          } else if (interval === 'every_two_weeks' && awardDay) {
            timing = `every other ${capitalizeFirstLetter(awardDay)}`
          }

          let expiry = ''
          if (expiryValue && expiryType) {
            expiry = `, expires after ${expiryValue} ${expiryType}${expiryValue > 1 ? 's' : ''}`
          }

          const autoApplyText = autoApply ? ' (automatically applied at checkout)' : ''

          return {
            type: attrs.type,
            text: `${amount} account credit ${timing}${expiry}${autoApplyText}`
          }
        }

        case 'FulfillmentMembershipPassReward':
          return {
            type: attrs.type,
            text: 'Free delivery on all orders!'
          }

        default:
          return null
      }
    }

    return {
      passRewards,
      sortedPassRewards,
      formatReward
    }
  }
})
